import {Cliente} from './Cliente';
import {PlacaVeiculo} from './PlacaVeiculo';
import {Vendedor} from './Vendedor';

export class CheckList {

  id: number;
  empresa: string;
  placaVeiculo: PlacaVeiculo;
  cliente: Cliente;
  kilometragem: number;
  dataHoraInicio: Date;
  dataHoraTermino: Date;
  antena: boolean;
  gps: boolean;
  carregadorCelular: boolean;
  radio: boolean;
  documentos: boolean;
  calotas: boolean;
  tapetes: boolean;
  manual: boolean;
  estepe: boolean;
  pertences: boolean;
  nivelCombustivel: number;
  servicosSolicitados: string;
  observacao: string;
  check1: boolean;
  check2: boolean;
  check3: boolean;
  check4: boolean;
  check5: boolean;
  check6: boolean;
  check7: boolean;
  check8: boolean;
  check9: boolean;
  check10: boolean;
  check11: boolean;
  check12: boolean;
  check13: boolean;
  check14: boolean;
  check15: boolean;
  check16: boolean;
  check17: boolean;
  check18: boolean;
  check19: boolean;
  check20: boolean;
  check21: boolean;
  check22: boolean;
  incluirData: Date;
  incluirUsuario: string;
  atendente: Vendedor;

  constructor(
    id?: number,
    empresa?: string,
    placaVeiculo?: PlacaVeiculo,
    cliente?: Cliente,
    kilometragem?: number,
    dataHoraInicio?: Date,
    dataHoraTermino?: Date,
    antena?: boolean,
    gps?: boolean,
    carregadorCelular?: boolean,
    radio?: boolean,
    documentos?: boolean,
    calotas?: boolean,
    tapetes?: boolean,
    manual?: boolean,
    estepe?: boolean,
    pertences?: boolean,
    nivelCombustivel?: number,
    servicosSolicitados?: string,
    observacao?: string,
    check1?: boolean,
    check2?: boolean,
    check3?: boolean,
    check4?: boolean,
    check5?: boolean,
    check6?: boolean,
    check7?: boolean,
    check8?: boolean,
    check9?: boolean,
    check10?: boolean,
    check11?: boolean,
    check12?: boolean,
    check13?: boolean,
    check14?: boolean,
    check15?: boolean,
    check16?: boolean,
    check17?: boolean,
    check18?: boolean,
    check19?: boolean,
    check20?: boolean,
    check21?: boolean,
    check22?: boolean,
    incluirData?: Date,
    incluirUsuario?: string,
    atendente?: Vendedor
  ) {
    this.id = id;
    this.empresa = empresa;
    this.placaVeiculo = placaVeiculo;
    this.cliente = cliente;
    this.kilometragem = kilometragem;
    this.dataHoraInicio = dataHoraInicio;
    this.dataHoraTermino = dataHoraTermino;
    this.antena = antena;
    this.gps = gps;
    this.carregadorCelular = carregadorCelular;
    this.radio = radio;
    this.documentos = documentos;
    this.calotas = calotas;
    this.tapetes = tapetes;
    this.manual = manual;
    this.estepe = estepe;
    this.pertences = pertences;
    this.nivelCombustivel = nivelCombustivel;
    this.servicosSolicitados = servicosSolicitados;
    this.observacao = observacao;
    this.check1 = check1;
    this.check2 = check2;
    this.check3 = check3;
    this.check4 = check4;
    this.check5 = check5;
    this.check6 = check6;
    this.check7 = check7;
    this.check8 = check8;
    this.check9 = check9;
    this.check10 = check10;
    this.check11 = check11;
    this.check12 = check12;
    this.check13 = check13;
    this.check14 = check14;
    this.check15 = check15;
    this.check16 = check16;
    this.check17 = check17;
    this.check18 = check18;
    this.check19 = check19;
    this.check20 = check20;
    this.check21 = check21;
    this.check22 = check22;
    this.incluirData = incluirData;
    this.incluirUsuario = incluirUsuario;
    this.atendente = atendente;
  }

}
