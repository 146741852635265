import { Contato } from "./Contato";
import { Endereco } from "./Endereco";

export class Pessoa {
  codigo: number;
  nome: string;
  endereco = new Endereco();
  ativo = true;
  contatos = new Array<Contato>();
}
