import { Produto } from 'app/core/models/Produto';

export class ProdutoEmpresa {
  id: number;
  cor: string;
  empresa: string;
  produto: Produto;
  eminimo: number;
  padrao: number;
  pedido: number;
  pendencia: number;
  quantidade: Float32Array;
  pedf: number;
  sugestao: number;
  emaximo: number;
  estadoIn: number;
  estadoOut: number;
  margem: number;
  codigoVeiculo: string;
  vmes: number;
  vm1: number;
  vm2: number;
  vm3: number;
  vm4: number;
  vm5: number;
  vm6: number;
  vmest: number;
  vm1T: number;
  vm2T: number;
  vm3T: number;
  vm4T: number;
  vm5T: number;
  vm6T: number;
  incluirUsuario: string;
  incluirComputador: string;
  incluirData: Date;
  excluirUsuario: string;
  excluirData: Date;
  excluirComputador: string;
  modificarOpcao: string;
  modificarUsuario: string;
  transito: number;
  modificarData: Date;
  constructor(
    id?: number,
    cor?: string,
    empresa?: string,
    produto = new Produto(),
    eminimo?: number,
    padrao?: number,
    pedido?: number,
    pendencia?: number,
    quantidade?: Float32Array,
    pedf?: number,
    sugestao?: number,
    emaximo?: number,
    estadoIn?: number,
    estadoOut?: number,
    margem?: number,
    codigoVeiculo?: string,
    vmes?: number,
    vm1?: number,
    vm2?: number,
    vm3?: number,
    vm4?: number,
    vm5?: number,
    vm6?: number,
    vmest?: number,
    vm1T?: number,
    vm2T?: number,
    vm3T?: number,
    vm4T?: number,
    vm5T?: number,
    vm6T?: number,
    incluirUsuario?: string,
    incluirComputador?: string,
    incluirData?: Date,
    excluirUsuario?: string,
    excluirData?: Date,
    excluirComputador?: string,
    modificarOpcao?: string,
    modificarUsuario?: string,
    transito?: number,
    modificarData?: Date
    ) {
    this.id = id;
    this.cor = cor;
    this.empresa = empresa;
    this.produto = produto;
    this.eminimo = eminimo;
    this.padrao = padrao;
    this.pedido = pedido;
    this.pendencia = pendencia;
    this.quantidade = quantidade;
    this.pedf = pedf;
    this.sugestao = sugestao;
    this.emaximo = emaximo;
    this.estadoIn = estadoIn;
    this.estadoOut = estadoOut;
    this.margem = margem;
    this.codigoVeiculo = codigoVeiculo;
    this.vmes = vmes;
    this.vm1 = vm1;
    this.vm2 = vm2;
    this.vm3 = vm3;
    this.vm4 = vm4;
    this.vm5 = vm5;
    this.vm6 = vm6;
    this.vmest = vmest;
    this.vm1T = vm1T;
    this.vm2T = vm2T;
    this.vm3T = vm3T;
    this.vm4T = vm4T;
    this.vm5T = vm5T;
    this.vm6T = vm6T;
    this.incluirUsuario = incluirUsuario;
    this.incluirComputador = incluirComputador;
    this.incluirData = incluirData;
    this.excluirUsuario = excluirUsuario;
    this.excluirData = excluirData;
    this.excluirComputador = excluirComputador;
    this.modificarOpcao = modificarOpcao;
    this.modificarUsuario = modificarUsuario;
    this.transito = transito;
    this.modificarData = modificarData;
  }
}
