import {Produto} from './Produto';


export class Movimento {
  aliquota: number;
  aliquotaCofinsPercentual: number;
  aliquotaIoi: number;
  aliquotaPisPercentual: number;
  aliquotaSubstituicaoTributaria: number;
  atualizarData: Date
  atualizarOperacao: string;
  cfop: string;
  cliente: string;
  codigo: string;
  codigoFornecedor: string;
  codigoServico: string;
  codigoSituacaoOperacaoSimplesNacional: string;
  codigoSituacaoTribuariaPis: string;
  codigoSituacaoTributariaCofins: string;
  codigoSituacaoTributariaIpi: string;
  codigoTecnico: string;
  cofinsstpcofins: number;
  cofinsstqbcprod: number;
  cofinsstvaliqprod: number;
  cofinsstvbc: number;
  cofinsstvcofins: number;
  conferent: string;
  crep: number;
  cstIcms: string;
  dataFinal: Date
  dataHora: Date
  dataHoraConferencia: Date
  dataHoraGuardou: Date
  dataInicial: Date
  desconto: string;
  descontoCofins: number;
  descontoIpi: number;
  descontoPis: number;
  dias: number;
  documento: string;
  empresa: string;
  excluirComputador: string;
  excluirData: Date
  excluirUsuario: string;
  fatorFinaceiro: number;
  fatorFrete: number;
  fatorImpostos: number;
  fatorOutros: number;
  fatorPrecoVenda: number;
  fatorVendaProgessiva: number;
  guardador: string;
  id: number;
  idLoteProduto: string;
  incluirComputador: string;
  incluirData: Date
  incluirUsuario: string;
  limite: number;
  lucroVenda: number;
  modbc: number;
  modbcst: string;
  modificarComputador: string;
  modificarData: Date
  modificarOperacao: string;
  modificarUsuario: string;
  motivoAcerto: string;
  nControle: string;
  ncm: string;
  notaDev: string;
  numeroPedido: string;
  orgi: string;
  pcredsn: number;
  pecas: number;
  pisstppis: number;
  pisstqbcprod: number;
  pisstvaliqprod: number;
  pisstvbc: number;
  pisstvpis: number;
  placaVeiculo: string;
  pmvast: number;
  preVenda: string;
  prebc: number;
  precoMinimo: string;
  precoVenda: number;
  predbcst: number;
  quantidade: number;
  quantidadeDev: number;
  quantidadeItensConf: number;
  quilometragem: number;
  rentabilidade: number;
  saldo: number;
  serie: string;
  serieDev: string;
  status: string;
  unidade: string;
  valorAliquotaIcms: number;
  valorBcCofins: number;
  valorBcIcms: number;
  valorBcIcmsSubstituicaoTributaria: number;
  valorBcIpi: number;
  valorBcPis: number;
  valorCofins: number;
  valorCusto: number;
  valorDesconto: number;
  valorFrete: number;
  valorIcms: number;
  valorIcmsSubstituicaoTributaria: number;
  valorIpi: number;
  valorNf: number;
  valorOutrasDespesasAcesso: number;
  valorPis: number;
  valorRedBc: number;
  valorSeguro: number;
  valorVenda: number;
  vbcstret: number;
  vcredicmssn: number;
  vicmsstret: number
  produto: Produto;
  qtdeLimite: number;
  vendedor: string;
  nomeFornecedor: string;

  constructor(
    aliquota?: number,
    aliquotaCofinsPercentual?: number,
    aliquotaIoi?: number,
    aliquotaPisPercentual?: number,
    aliquotaSubstituicaoTributaria?: number,
    atualizarData?: Date,
    atualizarOperacao?: string,
    cfop?: string,
    cliente?: string,
    codigo?: string,
    codigoFornecedor?: string,
    codigoServico?: string,
    codigoSituacaoOperacaoSimplesNacional?: string,
    codigoSituacaoTribuariaPis?: string,
    codigoSituacaoTributariaCofins?: string,
    codigoSituacaoTributariaIpi?: string,
    codigoTecnico?: string,
    cofinsstpcofins?: number,
    cofinsstqbcprod?: number,
    cofinsstvaliqprod?: number,
    cofinsstvbc?: number,
    cofinsstvcofins?: number,
    conferent?: string,
    crep?: number,
    cstIcms?: string,
    dataFinal?: Date,
    dataHora?: Date,
    dataHoraConferencia?: Date,
    dataHoraGuardou?: Date,
    dataInicial?: Date,
    desconto?: string,
    descontoCofins?: number,
    descontoIpi?: number,
    descontoPis?: number,
    dias?: number,
    documento?: string,
    empresa?: string,
    excluirComputador?: string,
    excluirData?: Date,
    excluirUsuario?: string,
    fatorFinaceiro?: number,
    fatorFrete?: number,
    fatorImpostos?: number,
    fatorOutros?: number,
    fatorPrecoVenda?: number,
    fatorVendaProgessiva?: number,
    guardador?: string,
    id?: number,
    idLoteProduto?: string,
    incluirComputador?: string,
    incluirData?: Date,
    incluirUsuario?: string,
    limite?: number,
    lucroVenda?: number,
    modbc?: number,
    modbcst?: string,
    modificarComputador?: string,
    modificarData?: Date,
    modificarOperacao?: string,
    modificarUsuario?: string,
    motivoAcerto?: string,
    nControle?: string,
    ncm?: string,
    notaDev?: string,
    numeroPedido?: string,
    orgi?: string,
    pcredsn?: number,
    pecas?: number,
    pisstppis?: number,
    pisstqbcprod?: number,
    pisstvaliqprod?: number,
    pisstvbc?: number,
    pisstvpis?: number,
    placaVeiculo?: string,
    pmvast?: number,
    preVenda?: string,
    prebc?: number,
    precoMinimo?: string,
    precoVenda?: number,
    predbcst?: number,
    quantidade?: number,
    quantidadeDev?: number,
    quantidadeItensConf?: number,
    quilometragem?: number,
    rentabilidade?: number,
    saldo?: number,
    serie?: string,
    serieDev?: string,
    status?: string,
    unidade?: string,
    valorAliquotaIcms?: number,
    valorBcCofins?: number,
    valorBcIcms?: number,
    valorBcIcmsSubstituicaoTributaria?: number,
    valorBcIpi?: number,
    valorBcPis?: number,
    valorCofins?: number,
    valorCusto?: number,
    valorDesconto?: number,
    valorFrete?: number,
    valorIcms?: number,
    valorIcmsSubstituicaoTributaria?: number,
    valorIpi?: number,
    valorNf?: number,
    valorOutrasDespesasAcesso?: number,
    valorPis?: number,
    valorRedBc?: number,
    valorSeguro?: number,
    valorVenda?: number,
    vbcstret?: number,
    vcredicmssn?: number,
    vicmsstret?: number,
    produto?: Produto,
    qtdeLimite?: number,
    vendedor?: string,
    nomeFornecedor?:string

  ) {
    this.aliquota = aliquota
    this.aliquotaCofinsPercentual = aliquotaCofinsPercentual
    this.aliquotaIoi = aliquotaIoi
    this.aliquotaPisPercentual = aliquotaPisPercentual
    this.aliquotaSubstituicaoTributaria = aliquotaSubstituicaoTributaria
    this.atualizarData = atualizarData
    this.atualizarOperacao = atualizarOperacao
    this.cfop = cfop
    this.cliente = cliente
    this.codigo = codigo
    this.codigoFornecedor = codigoFornecedor
    this.codigoServico = codigoServico
    this.codigoSituacaoOperacaoSimplesNacional = codigoSituacaoOperacaoSimplesNacional
    this.codigoSituacaoTribuariaPis = codigoSituacaoTribuariaPis
    this.codigoSituacaoTributariaCofins = codigoSituacaoTributariaCofins
    this.codigoSituacaoTributariaIpi = codigoSituacaoTributariaIpi
    this.codigoTecnico = codigoTecnico
    this.cofinsstpcofins = cofinsstpcofins
    this.cofinsstqbcprod = cofinsstqbcprod
    this.cofinsstvaliqprod = cofinsstvaliqprod
    this.cofinsstvbc = cofinsstvbc
    this.cofinsstvcofins = cofinsstvcofins
    this.conferent = conferent
    this.crep = crep
    this.cstIcms = cstIcms
    this.dataFinal = dataFinal
    this.dataHora = dataHora
    this.dataHoraConferencia = dataHoraConferencia
    this.dataHoraGuardou = dataHoraGuardou
    this.dataInicial = dataInicial
    this.desconto = desconto
    this.descontoCofins = descontoCofins
    this.descontoIpi = descontoIpi
    this.descontoPis = descontoPis
    this.dias = dias
    this.documento = documento
    this.empresa = empresa
    this.excluirComputador = excluirComputador
    this.excluirData = excluirData
    this.excluirUsuario = excluirUsuario
    this.fatorFinaceiro = fatorFinaceiro
    this.fatorFrete = fatorFrete
    this.fatorImpostos = fatorImpostos
    this.fatorOutros = fatorOutros
    this.fatorPrecoVenda = fatorPrecoVenda
    this.fatorVendaProgessiva = fatorVendaProgessiva
    this.guardador = guardador
    this.id = id
    this.idLoteProduto = idLoteProduto
    this.incluirComputador = incluirComputador
    this.incluirData = incluirData
    this.incluirUsuario = incluirUsuario
    this.limite = limite
    this.lucroVenda = lucroVenda
    this.modbc = modbc
    this.modbcst = modbcst
    this.modificarComputador = modificarComputador
    this.modificarData = modificarData
    this.modificarOperacao = modificarOperacao
    this.modificarUsuario = modificarUsuario
    this.motivoAcerto = motivoAcerto
    this.nControle = nControle
    this.ncm = ncm
    this.notaDev = notaDev
    this.numeroPedido = numeroPedido
    this.orgi = orgi
    this.pcredsn = pcredsn
    this.pecas = pecas
    this.pisstppis = pisstppis
    this.pisstqbcprod = pisstqbcprod
    this.pisstvaliqprod = pisstvaliqprod
    this.pisstvbc = pisstvbc
    this.pisstvpis = pisstvpis
    this.placaVeiculo = placaVeiculo
    this.pmvast = pmvast
    this.preVenda = preVenda
    this.prebc = prebc
    this.precoMinimo = precoMinimo
    this.precoVenda = precoVenda
    this.predbcst = predbcst
    this.quantidade = quantidade
    this.quantidadeDev = quantidadeDev
    this.quantidadeItensConf = quantidadeItensConf
    this.quilometragem = quilometragem
    this.rentabilidade = rentabilidade
    this.saldo = saldo
    this.serie = serie
    this.serieDev = serieDev
    this.status = status
    this.unidade = unidade
    this.valorAliquotaIcms = valorAliquotaIcms
    this.valorBcCofins = valorBcCofins
    this.valorBcIcms = valorAliquotaIcms
    this.valorBcIcmsSubstituicaoTributaria = valorBcIcmsSubstituicaoTributaria
    this.valorBcIpi = valorBcIpi
    this.valorBcPis = valorBcPis
    this.valorCofins = valorCofins
    this.valorCusto = valorCusto
    this.valorDesconto = valorDesconto
    this.valorFrete = valorFrete
    this.valorIcms = valorIcms
    this.valorIcmsSubstituicaoTributaria = valorBcIcmsSubstituicaoTributaria
    this.valorIpi = valorIpi
    this.valorNf = valorNf
    this.valorOutrasDespesasAcesso = valorOutrasDespesasAcesso
    this.valorPis = valorPis
    this.valorRedBc = valorRedBc
    this.valorSeguro = valorSeguro
    this.valorVenda = valorVenda
    this.vbcstret = vbcstret
    this.vcredicmssn = vcredicmssn
    this.vicmsstret = vicmsstret
    this.produto = produto
    this.qtdeLimite = qtdeLimite
    this.vendedor = vendedor
    this.nomeFornecedor = nomeFornecedor;
  }
}
