import { Permissao } from 'app/core/models/Permissao';
export class PerfilAcesso{
    id: number;
    nome: string;
    permissoes: Array<Permissao>;

    constructor(
     id?: number,
     nome?: string,
     permissoes = new Array<Permissao>()
     ) {

    this.id = id;
    this.nome = nome;
    this.permissoes = permissoes;
  }
}