export class Permissao{

    codigo: number;
    descricao: string;
    observacoes: string

    constructor(

     codigo?: number,
     descricao?: string,
     observacoes?: string
        
    ){

     this.codigo = codigo,
     this.descricao = descricao,
     this.observacoes = observacoes
    }
}