export class Veiculo {

    id: number;
    codigoVeiculo: string;
    descricao: string;
    modelo: string;
    motor: string;
    valvulas: string;
    cambio: string;
    combustivel: string;
    serie: string;
    tracao: string;

    constructor(

        id?: number,
        codigoVeiculo?: string,
        descricao?: string,
        modelo?: string,
        motor?: string,
        valvulas?: string,
        cambio?: string,
        combustivel?: string,
        serie?: string,
        tracao?: string

    ) {

        this.id = id;
        this.codigoVeiculo = codigoVeiculo;
        this.descricao = descricao;
        this.modelo = modelo;
        this.motor = motor;
        this.valvulas = valvulas;
        this.cambio = cambio;
        this.combustivel = combustivel;
        this.serie = serie;
        this.tracao = tracao;
        
    }
}