import { Cidade } from "./Cidade";

export class Endereco {
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  cidade = new Cidade();

  constructor(
    logradouro?: string,
    numero?: string,
    complemento?: string,
    bairro?: string,
    cep?: string
  ) {
    this.logradouro = logradouro;
    this.numero = numero;
    this.complemento = complemento;
    this.bairro = bairro;
    this.cep = cep;
  }
}
