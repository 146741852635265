import {Marca} from './Marca';
import {BalancoProdutoLocal} from './BalancoProdutoLocal';

export class Balanco{

    id: number;
    empresa: string;
    data: Date;
    marca: Marca;
    concluido: boolean;
    balancoProdutoLocalList: Array<BalancoProdutoLocal>;

    constructor(
        id?: number,
        empresa?: string,
        data?: Date,
        marca?: Marca,
        concluido?: boolean,
        balancoProdutoLocalList?: Array<BalancoProdutoLocal>
    ){
        this.id = id;
        this.empresa = empresa;
        this.data = data;
        this.marca = marca;
        this.concluido = concluido;
        this.balancoProdutoLocalList = balancoProdutoLocalList;
    }
    
}
