export class Local{

  empresa: string;
  bairro: number;
  rua: number;
  edificio: number;
  bloco: number;
  andar: number;
  apartamento: number;
  endereco: string;

  constructor(empresa?:string,
              bairro?:number,
              rua?:number,
              edificio?:number,
              bloco?:number,
              andar?:number,
              apartamento?: number,
              endereco?:string
  )
  {
    this.empresa = empresa;
    this.bairro = bairro;
    this.rua = rua;
    this.edificio = edificio;
    this.bloco= bloco;
    this.andar= andar;
    this.apartamento = apartamento;
    this.endereco = endereco;
  }

}
