export class ProdutoPesquisado{

  id: number;
  usuario: string;
  produto: string;
  data: Date;
  marcado: boolean;
  ordenacao: string;
  mostrar: boolean;

  constructor(
    id?: number,
    produto?: string,
    data?: Date,
    usuario?: string,
    marcado?: boolean,
    ordenacao?: string,
    mostrar?: boolean,
  ) {
    this.id = id;
    this.produto = produto;
    this.data= data;
    this.usuario= usuario;
    this.marcado= marcado;
    this.ordenacao = ordenacao;
    this.mostrar = mostrar;
  }


}
